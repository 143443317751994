<script setup>
import { ArrowPathIcon, CalendarIcon, TruckIcon } from '@heroicons/vue/24/outline'
import {defineComponent, h} from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faShieldCheck,
    faTruckFast,
    faCrab,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faBoxHeart,
} from '@fortawesome/sharp-solid-svg-icons'

const perks = [
    { name: '10-year all-inclusive warranty', description: 'We’ll replace it with a new one', icon: CalendarIcon },
    { name: 'Free shipping on returns', description: 'Send it back for free', icon: ArrowPathIcon },
    { name: 'Free, contactless delivery', description: 'The shipping is on us', icon: TruckIcon },
]

const navigation = {
    solutions: [
        { name: 'About Kuranda ShelterBeds', href: route('about') },
        { name: 'Contact Kuranda ShelterBeds', href: route('contact.create') },
        { name: 'Donor Login', href: route('login') },
    ],
    support: [
        { name: 'Privacy Policy', href: route('privacy') },
        { name: 'Terms of Use', href: route('termsOfUse') },
        { name: 'Accessibility', href: route('accessibility') },
    ],
    social: [
        {
            name: 'Facebook',
            href: 'https://www.facebook.com/KurandaDogBeds/',
            icon: defineComponent({
                render: () =>
                    h('svg', {fill: 'currentColor', viewBox: '0 0 24 24'}, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/kurandadogbeds',
            icon: defineComponent({
                render: () =>
                    h('svg', {fill: 'currentColor', viewBox: '0 0 24 24'}, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'X',
            href: 'https://twitter.com/kuranda',
            icon: defineComponent({
                render: () =>
                    h('svg', {fill: 'currentColor', viewBox: '0 0 24 24'}, [
                        h('path', {
                            d: 'M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z',
                        }),
                    ]),
            }),
        },
        {
            name: 'YouTube',
            href: 'https://www.youtube.com/kurandadogbeds',
            icon: defineComponent({
                render: () =>
                    h('svg', {fill: 'currentColor', viewBox: '0 0 24 24'}, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
    ],
}

</script>

<template>
    <div class="bg-white">
        <h2 class="sr-only">Our perks</h2>
        <div class="mx-auto max-w-7xl divide-y divide-gray-200 lg:flex lg:justify-center lg:divide-x lg:divide-y-0 pb-0 px-8 lg:px-0">
            <div class="py-8 lg:w-1/3 lg:flex-none lg:py-0">
                <div class="mx-auto flex items-center max-w-none lg:px-8">
                    <FontAwesomeIcon :icon="faShieldCheck" class="h-8 w-8 flex-shrink-0 text-indigo-600" aria-hidden="true" />
                    <div class="ml-4 flex flex-auto flex-col">
                        <h3 class="font-medium text-gray-900">1 Year Chew Proof Warranty</h3>
                        <p class="text-sm text-gray-500">We’ll replace any damaged parts for a full year.</p>
                    </div>
                </div>
            </div>
            <div class="py-8 lg:w-1/3 lg:flex-none lg:py-0">
                <div class="mx-auto flex items-center max-w-none lg:px-8">
                    <FontAwesomeIcon :icon="faTruckFast" class="h-8 w-8 flex-shrink-0 text-indigo-600" aria-hidden="true" />
                    <div class="ml-4 flex flex-auto flex-col">
                        <h3 class="font-medium text-gray-900">Free Shipping</h3>
                        <p class="text-sm text-gray-500">Shipping is free to the US lower 48 states.</p>
                    </div>
                </div>
            </div>
            <div class="py-8 lg:w-1/3 lg:flex-none lg:py-0">
                <div class="mx-auto flex items-center max-w-none lg:px-8">
                    <FontAwesomeIcon :icon="faBoxHeart" class="h-8 w-8 flex-shrink-0 text-indigo-600" aria-hidden="true" />
                    <div class="ml-4 flex flex-auto flex-col">
                        <h3 class="font-medium text-gray-900">Delivers directly to the shelter</h3>
                        <p class="text-sm text-gray-500">Tracking info emailed to you and to the shelter.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="relative max-w-7xl mx-auto my-14 mb-20">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"/>
        </div>
    </div>
    <footer class="bg-white" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
            <div class="grid grid-cols-1 lg:grid-cols-2 xl:gap-8">
                <div class="xl:mt-0 text-base leading-7 text-gray-700">
                    <p>
                        <FontAwesomeIcon :icon="faCrab" class="animate-bounce motion-reduce:animate-none mr-4 h-8 w-8 flex-shrink-0 text-blue-700" aria-hidden="true" />
                        Kuranda Beds are Manufactured in Glen Burnie, Maryland.
                    </p>
                    <p class="mt-6">
                        Kuranda ShelterBeds is operated by <a href="https://kuranda.com" class="text-gray-600 hover:text-gray-900 underline">Kuranda USA</a>, Inc.
                        <br>
                        796 Cromwell Park Drive
                        <br>
                        Ste A
                        <br>
                        Glen Burnie, Maryland 21061
                        <br>
                        <a href="mailto:contact@kuranda.com" class="leading-6 text-gray-600 hover:text-gray-900 underline">contact@kuranda.com</a>
                        <br>
                        <a href="tel:8007525308" class="leading-6 text-gray-600 hover:text-gray-900 underline">800-752-5308</a>
                    </p>
                </div>
                <div class="grid grid-cols-1 xl:grid-cols-2 gap-8 mt-4 xl:mt-0">
                    <div>
                        <h3 class="text-sm font-semibold leading-6 text-gray-900">About Us</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li v-for="item in navigation.solutions" :key="item.name">
                                <a :href="item.href"
                                   class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="text-sm font-semibold leading-6 text-gray-900">Legal</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li v-for="item in navigation.support" :key="item.name">
                                <a :href="item.href"
                                   class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div
                class="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:gap-3 lg:items-center lg:justify-between">
                <div>
                    <h3 class="text-sm font-semibold leading-6 text-gray-900">Subscribe to our newsletter</h3>
                    <p class="mt-2 text-sm leading-6 text-gray-600">Receive heartwarming success stories, highlights of shelters in need, and updates to our Kuranda ShelterBeds Program. Unsubscribe easily at any time.</p>
                </div>
                <form class="mt-6 sm:flex sm:max-w-md lg:mt-0" :action="route('subscribe.create')" method="get">
                    <label for="subscribe-email-address" class="sr-only">Email address</label>
                    <input type="email" name="email" id="subscribe-email-address" autocomplete="email" required
                           class="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:w-56 sm:text-sm sm:leading-6"
                           placeholder="Enter your email"/>
                    <div class="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                        <button type="submit"
                                class="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Subscribe
                        </button>
                    </div>
                </form>
            </div>
            <div class="mt-8 border-t border-gray-900/10 pt-8 md:flex md:items-center md:justify-between">
                <div class="flex space-x-6 md:order-2">
                    <a v-for="item in navigation.social" :key="item.name" :href="item.href"
                       class="text-gray-600 hover:text-gray-500">
                        <span class="sr-only">Kuranda on {{ item.name }}</span>
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true"/>
                    </a>
                </div>
                <p class="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">&copy; {{ new Date().getFullYear() }} Kuranda USA, Inc. All rights reserved.</p>
            </div>
        </div>
    </footer>
</template>

<style scoped>

</style>
