<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
    <div>
        <label for="shelter-search-query" class="block text-left md:text-center font-medium leading-6 text-white md:text-gray-900">Search for a Shelter</label>
        <div class="relative mt-2">
            <form @submit.prevent="search()">
                <div class="grid grid-cols-6">
                    <label for="search_type" class="sr-only">Choose how you'd like to search</label>
                    <select id="search_type" name="search_type" v-model="search_type" class="col-span-6 lg:col-span-2 h-full w-full border-0 lg:py-0 pl-7 lg:pl-4 pr-7 text-gray-500 rounded-md lg:rounded-r-none ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:mx-auto">
                        <option value="name">Search for Shelters Named</option>
                        <option value="location">Search for Shelters Near</option>
                    </select>
                    <input type="text" :placeholder="searchPlaceholder()" name="shelter-search-query" id="shelter-search-query" class="mt-4 lg:mt-0 col-span-6 lg:col-span-4 block rounded-md lg:rounded-l-none border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 lg:text-sm lg:leading-6" v-model="search_query" />
                    <button type="submit" class="col-span-6 lg:col-span-2 lg:col-start-3 mt-4 w-full rounded bg-orange-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-orange-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Search</button>
                </div>
            </form>
        </div>
        <div class="px-4 sm:px-6 lg:px-8">
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 text-white md:text-gray-800">
                        <template v-if="loading === true">
                            <div role="status" class="m-8">
                                <svg aria-hidden="true" class="w-8 h-8 animate-spin fill-indigo-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span class="sr-only">Loading search results...</span>
                            </div>
                        </template>
                        <p v-if="rate_limit_reached === true && showing_search_results && !loading" class="text-base leading-7" role="alert">
                            Too Many Search Attempts. Please Try Again In A Moment.
                        </p>
                        <p  v-if="random_rate_limit_reached === true && showing_random_results && !loading" class="text-base leading-7" role="alert">
                            Too Many Search Attempts. Please Try Again In A Moment.
                        </p>
                        <div v-if="search_error_message !== null && showing_search_results && !loading">
                            <p class="my-4 text-xl font-alternate" v-html="search_error_message" role="alert" />
                            <p class="text-base leading-7">
                                Can’t find a group you want to donate to? Email <a href="mailto:kurandapro@kuranda.com" class="text-white underline md:text-gray-800">KurandaPro@kuranda.com</a>.
                                or you can choose show random shelters below.
                            </p>
                        </div>
                        <table class="min-w-full divide-y divide-gray-300" v-if="organizations.length" role="list" :aria-label="getResultListAriaLabel()">
                            <tbody class="bg-white">
                            <tr v-for="organization in organizations" :key="organization.id" class="even:bg-gray-50" role="listitem" :aria-labelledby="`search-result-${organization.id}`">
                                <td class="max-w-48 md:max-w-full py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3" :id="`search-result-${organization.id}`">
                                    <strong>{{ organization.name }}</strong> in {{ organization.city }}, {{ organization.state }}
                                </td>
                                <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    <div class="grid grid-cols-2 md:gap-3">
                                        <a :href="organization.wishlist_route" class="col-span-2 mb-4 md:mb-0 md:col-span-1 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-center">
                                            View Wish List
                                            <span class="sr-only">for {{ organization.name }}</span>
                                        </a>
                                        <a :href="route('wishList.help', organization.id)" class="col-span-2 md:col-span-1 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-center">
                                            Free Ways to Help
                                            <span class="sr-only">{{ organization.name }}</span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="isolate inline-flex rounded-md shadow-sm my-4">
                            <button type="button" class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" @click.prevent="searchMyLocation">Show Shelters Near Me</button>
                            <button type="button" class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10" @click.prevent="getRandomShelters">Show Random Shelters</button>
                        </div>
                        <template v-if="shelter_id && shelter_id > 0">
                            <p class="text-base leading-7 mt-4">
                                <a :href="route('wishList.show', shelter_id)" class="text-white md:text-gray-600 underline">
                                    Resume donation
                                    <template v-if="shelter_name && shelter_name !== ''">
                                        to {{ shelter_name }}
                                    </template>
                                    &nbsp;<span aria-hidden="true">&rarr;</span>
                                </a>
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import {onMounted, ref} from 'vue'
import * as _ from "lodash-es";
const tabs = [
    { name: 'Search by Location', href: '#', current: true },
    { name: 'Search by Organization Name', href: '#', current: false },
]
let searchResults = ref([]);
const props = defineProps({
    originalSearchQuery: {
        type: String,
        default: '',
    },
    originalShelterId: {
        type: String,
        default: '0',
    },
    originalShelterName: {
        type: String,
        default: '0',
    },
    originalSearchOnLoad: {
        type: String,
        default: '0',
    },
});
let search_query = ref('');
let shelter_id = ref(null);
let shelter_name = ref(null);
let organizations = ref([]);
let search_error_message = ref(null);
let rate_limit_reached = ref(false);
let loading = ref(false);
let searched_once = ref(false);
let showing_search_results = ref(false);
let showing_random_results = ref(false);
let random_rate_limit_reached = ref(false);
let random_searched_once = ref(false);
let search_type = ref('name');
let location_search_type = ref(null);
let search_on_load = ref(false);
onMounted(() => {
    search_query.value = props.originalSearchQuery;
    shelter_id.value = parseInt(props.originalShelterId, 10);
    shelter_name.value = props.originalShelterName;
    search_on_load.value = props.originalSearchOnLoad === '1';
    if (search_on_load.value === true) {
        search_type.value = 'location';
        search();
    }
})
function helpRoute(shelterId) {
    return route('wishList.help', {shelter: shelterId});
}
function getResultListAriaLabel() {
    if (showing_random_results.value === true) {
        return 'Random Shelter Search Results';
    }
    if (location_search_type.value === 'query_name') {
        return 'Search by Name Results';
    }
    if (location_search_type.value === 'my_location') {
        return 'Results Near Me';
    }
    return 'Search by Location Results';
}
function searchMyLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
        search({
            longitude:   _.get(position, 'coords.longitude'),
            latitude:   _.get(position, 'coords.latitude'),
        });
    }, function(error) {
        alert('Sorry, we are unable to find your current location. Please try another search above.')
    }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    });
}
function searchPlaceholder() {
    if (isNameSearch()) {
        return '';
    }
    return 'Use postal code or city and state/province for best results.';
}
function isNameSearch () {
    return search_type.value === 'name';
}
function isLocationSearch () {
    return search_type.value === 'location';
}
// function sendRandomClickEvent (shelterId) {
//     try {
//         shelterBeds.googleAnalyticsSendCustomEvent('random_shelter_click', {shelterId: shelterId});
//         if (window.clarity) {
//             window.clarity('set', 'random_shelter_click', shelterId);
//         }
//     } catch (exception) {
//         //
//     }
// }
function getRandomShelters() {
    if (organizations.length) {
        showing_search_results.value = false;
        showing_random_results.value = true;
        return;
    }
    loading.value = true;
    random_rate_limit_reached.value = false;
    organizations.value = [];
    location_search_type.value = null;
    axios.post(route('getRandomShelters'))
        .then(function (response) {
            showing_search_results.value = false;
            showing_random_results.value = true;
            organizations.value = response.data.organizations;
            // try {
            //     window.gtag('event', 'random_search', {
            //         search_source : 'front_page',
            //         search_result_error : 0,
            //     });
            // } catch (e) {
            //     console.error(e);
            //     // ignore error so we don't interrupt mission critical JS
            // }
        })
        // error code taken from https://axios-http.com/docs/handling_errors
        .catch(function (error) {
            if (error.response) {
                if (error.response.status && error.response.status === 429 ) {
                    showing_random_results.value = true;
                    showing_search_results.value = false;
                    random_rate_limit_reached.value = true;
                    return;
                }
            } else if (error.request) {

            } else {

            }
            // log error in GA regardless of error state
            // try {
            //     window.gtag('event', 'random_search', {
            //         search_source : 'front_page',
            //         search_result_error : 1,
            //     });
            // } catch (e) {
            //     // ignore error so we don't interrupt mission critical JS
            // }
        })
        .finally(function () {
            loading.value = false;
        });
}
function search(options = {}) {
    loading.value = true;
    search_error_message.value = null;
    rate_limit_reached.value = false;
    organizations.value = [];
    showing_search_results.value = true;
    showing_random_results.value = false;
    const latitude = _.get(options, 'latitude', null);
    const longitude = _.get(options, 'longitude', null);
    let searchRoute = '';
    let postBody = {};
    location_search_type.value = null;
    if(latitude!==null && longitude!==null) {
        searchRoute = route('getSheltersForLocationCoordinates');
        postBody = {
            longitude,
            latitude,
        };
        location_search_type.value = 'my_location';
    } else if (isNameSearch()) {
        searchRoute = route('getSheltersByName');
        postBody = {
            name: search_query.value,
        };
        location_search_type.value = 'query_name';
    } else {
        searchRoute = route('getSheltersForLocation');
        postBody = {
            address: search_query.value,
        };
        location_search_type.value = 'query_location';
    }
    axios.post(searchRoute, postBody)
        .then(function (response) {
            organizations.value = response.data.organizations;
            search_error_message.value = response.data.error_message ? response.data.error_message : null;
            // try {
            //     window.gtag('event', 'search', {
            //         search_term : search_query.value,
            //         search_type : localSearchType !== null ? localSearchType : search_type.value,
            //         search_source : 'front_page',
            //         search_result_count : response.data.organizations.length,
            //         search_result_error : 0,
            //     });
            // } catch (e) {
            //     console.error(e);
            //     // ignore error so we don't interrupt mission critical JS
            // }
        })
        // error code taken from https://axios-http.com/docs/handling_errors
        .catch(function (error) {
            if (error.response) {
                if (error.response.status && error.response.status === 429 ) {
                    rate_limit_reached.value = true;
                    return;
                }
                if (error.response.status && error.response.status === 422 ) {
                    search_error_message.value = 'Please enter a search query';
                    return;
                }
                // backend responded with something other than 200
                // get http error status code
                // console.log(error.response.data);
                // if we want to detect something like rate limiting response status:
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                // console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }
            // log error in GA regardless of error state
            // try {
            //     window.gtag('event', 'search', {
            //         search_term : search_query.value,
            //         search_type : localSearchType !== null ? localSearchType : search_type.value,
            //         search_source : 'front_page',
            //         search_result_count : 0,
            //         search_result_error : 1,
            //     });
            // } catch (e) {
            //     // ignore error so we don't interrupt mission critical JS
            // }
        })
        .finally(function () {
            loading.value = false;
            searched_once.value = true;
        });
}
</script>
