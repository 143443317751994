<template>
    <div>
        <h2 class="mt-2 text-2xl font-bold tracking-tight text-gray-900">Add a Tribute Card to Your Order? It's free!</h2>
        <p class="mt-2">
            Tribute cards are a wonderful way to remember a loved one or cherished pet or to give the perfect gift to someone who has “everything.” Celebrate special occasions AND help animals at the same time!
            <span class="italic">Limit 1 card per order.</span>
        </p>
        <section aria-labelledby="tribute-card-recipient-heading" class="mt-10">
            <h3 id="tribute-card-recipient-heading" class="text-lg font-medium text-gray-900">Tribute Card Recipient Details:</h3>
            <form method="post" :action="route('checkout.complete.tributeCard.create')">
                <input type="hidden" :value="csrfToken" name="_token" autocomplete="off">
                <div class="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                    <div>
                        <form-field
                            field-name="tribute_card_first_name"
                            label-text="First Name"
                            v-model="firstName"
                            :max-length="tributeCardFieldVarcharMaxLength"
                            :error-messages="getSessionErrorsForField('tribute_card_first_name')"
                        ></form-field>
                    </div>
                    <div>
                        <form-field
                            field-name="tribute_card_last_name"
                            label-text="Last Name"
                            v-model="lastName"
                            :max-length="tributeCardFieldVarcharMaxLength"
                            :error-messages="getSessionErrorsForField('tribute_card_last_name')"
                        ></form-field>
                    </div>

                    <div class="sm:col-span-2">
                        <form-field
                            field-name="tribute_card_address_1"
                            label-text="Address 1"
                            auto-complete-type="street-address"
                            v-model="address1"
                            :max-length="tributeCardFieldVarcharMaxLength"
                            :error-messages="getSessionErrorsForField('tribute_card_address_1')"
                        ></form-field>
                    </div>

                    <div class="sm:col-span-2">
                        <form-field
                            field-name="tribute_card_address_2"
                            :optional="true"
                            label-text="Apartment, suite, etc."
                            v-model="address2"
                            :max-length="tributeCardFieldVarcharMaxLength"
                            :error-messages="getSessionErrorsForField('tribute_card_address_2')"
                        ></form-field>
                    </div>

                    <div>
                        <form-field
                            field-name="tribute_card_city"
                            label-text="City"
                            auto-complete-type="address-level2"
                            v-model="city"
                            :max-length="tributeCardMessageMaxLength"
                            :error-messages="getSessionErrorsForField('tribute_card_city')"
                        ></form-field>
                    </div>

                    <div>
                        <label for="tribute_card_zone_id" class="block text-sm font-medium text-gray-700">
                            State / Province
                        </label>
                        <div class="mt-1">
                            <select
                                id="tribute_card_zone_id"
                                name="tribute_card_zone_id"
                                autocomplete="address-level1"
                                :class="getSelectInputClasses('tribute_card_zone_id')"
                                v-model="zoneId"
                                :aria-invalid="hasSessionErrorsForField('tribute_card_zone_id') ? true : null"
                                :aria-describedby="hasSessionErrorsForField('tribute_card_zone_id') ? 'tribute_card_zone_id_error' : null"
                                required
                            >
                                <option value="0">Select a State/Province</option>
                                <option v-for="state in tributeCardZoneNames" :value="state.id" v-html="state.name" />
                            </select>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="hasSessionErrorsForField('tribute_card_zone_id')" id="tribute_card_zone_id_error" v-html="getSessionErrorsForField('tribute_card_zone_id').join('<br>')" />
                    </div>

                    <div v-if="parseInt(zoneId, 10) === puertoRicoZoneId" class="sm:col-span-2">
                        <form-field
                            field-name="tribute_card_urbanization"
                            label-text="Urbanization"
                            v-model="urbanization"
                            :max-length="tributeCardMessageMaxLength"
                            :error-messages="getSessionErrorsForField('tribute_card_urbanization')"
                        ></form-field>
                    </div>

                    <div>
                        <form-field
                            field-name="tribute_card_postal_code"
                            label-text="Postal Code"
                            auto-complete-type="postal-code"
                            v-model="postalCode"
                            :max-length="tributeCardFieldVarcharMaxLength"
                            :error-messages="getSessionErrorsForField('tribute_card_postal_code')"
                        ></form-field>
                    </div>
                    <div>
                        <label for="tribute_card_country_id" class="block text-sm font-medium text-gray-700">
                            Country
                        </label>
                        <div class="mt-1">
                            <select
                                id="tribute_card_country_id"
                                name="tribute_card_country_id"
                                autocomplete="country"
                                :class="getSelectInputClasses('tribute_card_country_id')"
                                v-model="countryId"
                                :aria-invalid="hasSessionErrorsForField('tribute_card_country_id') ? true : null"
                                :aria-describedby="hasSessionErrorsForField('tribute_card_country_id') ? 'tribute_card_country_error' : null"
                                required
                            >
                                <option v-for="country in tributeCardCountryNames" :value="country.id" v-html="country.name" />
                            </select>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="hasSessionErrorsForField('tribute_card_country_id')" id="tribute_card_country_error" v-html="getSessionErrorsForField('tribute_card_country_id').join('<br>')" />
                    </div>
                    <div class="sm:col-span-2">
                        <label for="tribute_card_message" class="block text-sm font-medium leading-6 text-gray-900">
                            Message for Tribute Card Recipient
                        </label>
                        <div class="mt-2">
                            <textarea
                                rows="4"
                                name="tribute_card_message"
                                id="tribute_card_message"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                v-model="message"
                                :maxlength="tributeCardMessageMaxLength"
                                required
                                :aria-invalid="hasSessionErrorsForField('tribute_card_message') ? true : null"
                                :aria-describedby="hasSessionErrorsForField('tribute_card_message') ? 'tribute_card_message_error' : null"
                            />
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="hasSessionErrorsForField('tribute_card_message')" id="tribute_card_message_error" v-html="getSessionErrorsForField('tribute_card_message').join('<br>')" />
                    </div>
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <button type="submit" class="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:w-auto disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" :disabled="submitButtonIsDisabled">Submit</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>


<script setup>
import {onMounted, ref} from "vue";
import FormField from "./FormField.vue";
import * as _ from "lodash-es";

    function submitForm() {
        this.submitButtonIsDisabled = true;
    }
    function getSessionErrorsForField(fieldName)
    {
        return _.get(props.formValidationErrors, fieldName, []);
    }
    function hasSessionErrorsForField(fieldName)
    {
        return getSessionErrorsForField(fieldName).length;
    }
    function getSelectInputClasses(fieldName)
    {
        const commonClasses = 'mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset focus:ring-2 sm:text-sm sm:leading-6';
        if (hasSessionErrorsForField(fieldName)) {
            return `${commonClasses} text-red-900 ring-red-300 focus:ring-red-500`;
        }
        return `${commonClasses} text-gray-900 ring-gray-300 focus:ring-indigo-500`;
    }
    const props = defineProps({
        tributeCardFieldVarcharMaxLength: {
            type: Number,
        },
        tributeCardMessageMaxLength: {
            type: Number,
        },
        // pass these in as props so we can repopulate when form validation fails.
        tributeCardFirstName: {
            type: String,
            default: null,
        },
        tributeCardLastName: {
            type: String,
            default: null,
        },
        tributeCardAddress1: {
            type: String,
            default: null,
        },
        tributeCardAddress2: {
            type: String,
            default: null,
        },
        tributeCardUrbanization: {
            type: String,
            default: null,
        },
        tributeCardCity: {
            type: String,
            default: null,
        },
        tributeCardZoneId: {
            type: String,
            default: '',
        },
        tributeCardPostalCode: {
            type: String,
        },
        tributeCardCountryId: {
            type: String,
            default: '',
        },
        tributeCardMessage: {
            type: String,
            default: '',
        },
        tributeCardZoneNames: {
            type: Array,
        },
        tributeCardCountryNames: {
            type: Array,
        },
        fromFirstName: {
            type: String,
        },
        shelterName: {
            type: String,
        },
        csrfToken: {
            type: String,
        },
        puertoRicoZoneId: {
            type: Number,
        },
        formValidationErrors: {
            type: Object,
            default: {},
        },
    })
    const firstName = ref('');
    const lastName = ref('');
    const address1 = ref('');
    const address2 = ref('');
    const city = ref('');
    const zoneId = ref('');
    const postalCode = ref('');
    const urbanization = ref('');
    const countryId = ref('');
    const message = ref('');
    const submitButtonIsDisabled = ref(false);
    onMounted(() => {
        firstName.value = props.tributeCardFirstName;
        lastName.value = props.tributeCardLastName;
        address1.value = props.tributeCardAddress1;
        address2.value = props.tributeCardAddress2;
        city.value = props.tributeCardCity;
        zoneId.value = props.tributeCardZoneId;
        postalCode.value = props.tributeCardPostalCode;
        urbanization.value = props.tributeCardUrbanization;
        countryId.value = props.tributeCardCountryId;
        message.value = props.tributeCardMessage;
    })
</script>

<style scoped>

</style>
