<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
    <form method="post" :action="route('checkout.store')" id="checkout-form" @submit.prevent="submitForm">
        <input type="hidden" :value="props.csrfToken" name="_token" autocomplete="off">
        <div class="bg-white relative">
            <!-- Background color split screen for large screens -->
<!--            <div class="fixed left-0 top-0 hidden h-full w-1/2 bg-white lg:block" aria-hidden="true" />-->
<!--            <div class="fixed right-0 top-0 hidden h-full w-1/2 bg-gray-50 lg:block" aria-hidden="true" />-->

            <header class="relative border-b border-gray-200 bg-white text-sm font-medium text-gray-700">
                <div class="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                    <div class="relative flex justify-end sm:justify-center">
                        <nav aria-label="Progress">
                            <ol role="list" class="flex space-x-4">
                                <li class="flex items-center">
                                    <a :href="wishListLink">
                                        {{ shelterName }}
                                    </a>
                                    <ChevronRightIcon class="ml-4 h-5 w-5 text-gray-300" aria-hidden="true" />
                                </li>
                                <li class="flex items-center">
                                    <a :href="route('cart.index')">Cart</a>
                                    <ChevronRightIcon class="ml-4 h-5 w-5 text-gray-300" aria-hidden="true" />
                                </li>
                                <li class="flex items-center">
                                    <a href="#" aria-current="page" class="text-indigo-600">Checkout</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </header>

            <div class="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-36">
                <h1 class="sr-only">Order information</h1>

                <section aria-labelledby="summary-heading" class="bg-gray-50 px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:pb-16 lg:px-8">
                    <div class="mx-auto max-w-lg lg:max-w-none">
                        <h2 id="summary-heading" class="text-lg font-medium text-gray-900">Order summary</h2>

                        <p>Your order ships directly to {{ shelterName }} in {{ shelterCity }}, {{ shelterState }}</p>

                        <ul role="list" class="divide-y divide-gray-200 text-sm font-medium text-gray-900">
                            <li v-for="product in products" :key="product.cart_product_id" class="flex items-start space-x-4 py-6">
                                <img :src="product.image" :alt="product.image_alt_text" class="h-20 w-20 flex-none rounded-md object-contain object-center bg-white" />
                                <div class="flex-auto space-y-1">
                                    <h3>
                                        {{ product.name }}
                                    </h3>
                                    <p class="text-gray-500" v-if="product.description">
                                        {{ product.description }}
                                    </p>
                                    <p class="text-gray-500">
                                        Quantity: {{ product.quantity }}
                                    </p>
                                </div>
                                <div class="flex-none text-base font-medium">
                                    <p v-if="product.original_price && product.original_price > product.unit_price" class="mt-1 text-sm font-medium text-gray-600 line-through">${{ product.original_price.toFixed(2) }}</p>
                                    {{ product.unit_price.toFixed(2) }}
                                </div>
                            </li>
                        </ul>

                        <dl class="hidden z-10 space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
                            <div class="flex items-center justify-between">
                                <dt class="text-gray-600">Subtotal</dt>
                                <dd>${{ productTotal.toFixed(2) }}</dd>
                            </div>

                            <div class="flex items-center justify-between">
                                <dt class="text-gray-600">
                                    Shipping
                                    <span class="text-gray-600 block italic" v-html="shippingQuote.description" />
                                </dt>
                                <dd>${{ shippingQuote.amount }}</dd>
                            </div>

                            <div class="flex items-center justify-between">
                                <dt class="text-gray-600">Taxes</dt>
                                <dd>${{ taxTotal.toFixed(2) }}</dd>
                            </div>

                            <template v-if="fees.length">
                                <div class="flex items-center justify-between" v-for="(fee, feeIndex) in fees" :key="feeIndex">
                                    <dt class="text-gray-600" v-html="fee.description" />
                                    <dd>${{ fee.amount }}</dd>
                                </div>
                            </template>

                            <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                                <dt class="text-base">Total</dt>
                                <dd class="text-base">${{ grandTotal.toFixed(2) }}</dd>
                            </div>
                        </dl>

                        <Popover class="fixed z-10 inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden" v-slot="{ open }">
                            <div class="relative z-10 border-t border-gray-200 bg-white px-4 sm:px-6">
                                <div class="mx-auto max-w-lg">
                                    <PopoverButton class="flex w-full items-center py-6 font-medium">
                                        <span class="mr-auto text-base">Total</span>
                                        <span class="mr-2 text-base">${{ grandTotal.toFixed(2) }}</span>
                                        <ChevronUpIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </PopoverButton>
                                </div>
                            </div>

                            <TransitionRoot as="template" :show="open">
                                <div>
                                    <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                                        <PopoverOverlay class="fixed inset-0 bg-black bg-opacity-25" />
                                    </TransitionChild>

                                    <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="translate-y-full" enter-to="translate-y-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-y-0" leave-to="translate-y-full">
                                        <PopoverPanel class="relative z-10 bg-white px-4 py-6 sm:px-6">
                                            <dl class="mx-auto max-w-lg space-y-6">
                                                <div class="flex items-center justify-between">
                                                    <dt class="text-gray-600">Subtotal</dt>
                                                    <dd>${{ productTotal.toFixed(2) }}</dd>
                                                </div>

                                                <div class="flex items-center justify-between">
                                                    <dt class="text-gray-600">
                                                        Shipping
                                                        <span class="text-gray-600 block italic" v-html="shippingQuote.description" />
                                                    </dt>
                                                    <dd>${{ shippingQuote.amount }}</dd>
                                                </div>

                                                <div class="flex items-center justify-between">
                                                    <dt class="text-gray-600">Taxes</dt>
                                                    <dd>${{ taxTotal.toFixed(2) }}</dd>
                                                </div>

                                                <template v-if="fees.length">
                                                    <div class="flex items-center justify-between" v-for="(fee, feeIndex) in fees" :key="feeIndex">
                                                        <dt class="text-gray-600" v-html="fee.description" />
                                                        <dd>${{ fee.amount }}</dd>
                                                    </div>
                                                </template>
                                            </dl>
                                        </PopoverPanel>
                                    </TransitionChild>
                                </div>
                            </TransitionRoot>
                        </Popover>
                    </div>
                </section>

                <div class="px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
                    <div class="mx-auto max-w-lg lg:max-w-none">
                        <section aria-labelledby="contact-info-heading">
                            <h2 id="contact-info-heading" class="text-lg font-medium text-gray-900">Contact information</h2>

                            <div class="mt-6">
                                <form-field
                                    field-type="email"
                                    field-name="customer_email"
                                    label-text="Email address"
                                    auto-complete-type="email"
                                    v-model="localEmail"
                                    :max-length="orderTableVarcharMaxLength"
                                    :error-messages="getSessionErrorsForField('customer_email')"
                                    ></form-field>
                            </div>
                        </section>

                        <section aria-labelledby="billing-heading" class="mt-10">
                            <h2 id="billing-heading" class="text-lg font-medium text-gray-900">Billing information</h2>
                            <div class="flex mt-2">
                                <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400 mr-3" aria-hidden="true" />
                                <p class="text-sm text-gray-900">Please ensure this address matches the address on file with your payment method.</p>
                            </div>
                            <div class="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                                <div>
                                    <form-field
                                        field-name="customer_first_name"
                                        label-text="First Name"
                                        v-model="localFirstName"
                                        :max-length="orderTableVarcharMaxLength"
                                        :error-messages="getSessionErrorsForField('customer_first_name')"
                                    ></form-field>
                                </div>
                                <div>
                                    <form-field
                                        field-name="customer_last_name"
                                        label-text="Last Name"
                                        v-model="localLastName"
                                        :max-length="orderTableVarcharMaxLength"
                                        :error-messages="getSessionErrorsForField('customer_last_name')"
                                    ></form-field>
                                </div>
                                <div class="sm:col-span-2">
                                    <form-field
                                        field-name="customer_company"
                                        label-text="Company"
                                        :optional="true"
                                        v-model="localCompany"
                                        :max-length="orderTableVarcharMaxLength"
                                        :error-messages="getSessionErrorsForField('customer_company')"
                                    ></form-field>
                                </div>

                                <div class="sm:col-span-2">
                                    <form-field
                                        field-name="customer_address_1"
                                        label-text="Address"
                                        auto-complete-type="street-address"
                                        v-model="localAddress1"
                                        :max-length="orderTableVarcharMaxLength"
                                        :error-messages="getSessionErrorsForField('customer_address_1')"
                                    ></form-field>
                                </div>

                                <div class="sm:col-span-2">
                                    <form-field
                                        field-name="customer_address_2"
                                        :optional="true"
                                        label-text="Apartment, suite, etc."
                                        v-model="localAddress2"
                                        :max-length="orderTableVarcharMaxLength"
                                        :error-messages="getSessionErrorsForField('customer_address_2')"
                                    ></form-field>
                                </div>

                                <div>
                                    <form-field
                                        field-name="customer_city"
                                        label-text="City"
                                        auto-complete-type="address-level2"
                                        v-model="localCity"
                                        :max-length="orderTableVarcharMaxLength"
                                        :error-messages="getSessionErrorsForField('customer_city')"
                                    ></form-field>
                                </div>

                                <div>
                                    <label for="customer_state" class="block text-sm font-medium text-gray-700">
                                        State / Province
                                    </label>
                                    <div class="mt-1">
                                        <select
                                            id="customer_state"
                                            name="customer_state"
                                            autocomplete="address-level1"
                                            :class="getSelectInputClasses('customer_state')"
                                            v-model="localZoneId"
                                            :aria-invalid="hasSessionErrorsForField('customer_state') ? true : null"
                                            :aria-describedby="hasSessionErrorsForField('customer_state') ? 'customer_state_error' : null"
                                            required
                                        >
                                            <option v-for="(state, zoneId) in zonesList" :value="zoneId" v-html="state" />
                                        </select>
                                    </div>
                                    <p class="mt-2 text-sm text-red-600" v-if="hasSessionErrorsForField('customer_state')" id="customer_state_error" v-html="getSessionErrorsForField('customer_state').join('<br>')" />
                                </div>
                                <div>
                                    <form-field
                                        field-name="customer_postal_code"
                                        label-text="Postal Code"
                                        auto-complete-type="postal-code"
                                        v-model="localPostalCode"
                                        :max-length="orderTableVarcharMaxLength"
                                        :error-messages="getSessionErrorsForField('customer_postal_code')"
                                    ></form-field>
                                </div>
                                <div>
                                    <label for="customer_country" class="block text-sm font-medium text-gray-700">
                                        Country
                                    </label>
                                    <div class="mt-1">
                                        <select
                                            id="customer_country"
                                            name="customer_country"
                                            autocomplete="country"
                                            :class="getSelectInputClasses('customer_country')"
                                            v-model="localCountryId"
                                            :aria-invalid="hasSessionErrorsForField('customer_country') ? true : null"
                                            :aria-describedby="hasSessionErrorsForField('customer_country') ? 'customer_country_error' : null"
                                            required
                                        >
                                            <option v-for="(country, countryId) in countriesList" :value="countryId" v-html="country" />
                                        </select>
                                    </div>
                                    <p class="mt-2 text-sm text-red-600" v-if="hasSessionErrorsForField('customer_country')" id="customer_country_error" v-html="getSessionErrorsForField('customer_country').join('<br>')" />
                                </div>
                                <div class="sm:col-span-2">
                                    <form-field
                                        field-type="tel"
                                        auto-complete-type="tel"
                                        field-name="customer_phone"
                                        label-text="Phone"
                                        v-model="localPhone"
                                        :max-length="orderTableVarcharMaxLength"
                                        :error-messages="getSessionErrorsForField('customer_phone')"
                                    ></form-field>
                                </div>
                            </div>
                        </section>

                        <fieldset class="my-8">
                            <legend class="sr-only">Information Sharing and Email Subscription</legend>
                            <div class="space-y-5">
                                <div class="relative flex items-start">
                                    <div class="flex h-6 items-center">
                                        <input id="anonymous" aria-describedby="anonymous-description" name="anonymous" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="localAnonymous" />
                                    </div>
                                    <div class="ml-3 text-sm leading-6">
                                        <label for="anonymous" class="font-medium text-gray-900">Anonymous Donation</label>
                                        <p id="anonymous-description" class="text-gray-500"> If left unchecked, the shelter receiving your donation will receive your contact info in case they would like to send a thank you letter.
                                            Please check this box if you prefer to remain anonymous.</p>
                                    </div>
                                </div>
                                <div class="relative flex items-start">
                                    <div class="flex h-6 items-center">
                                        <input id="email_opt_in" aria-describedby="email_opt_in-description" name="email_opt_in" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="localSubscribe" />
                                    </div>
                                    <div class="ml-3 text-sm leading-6">
                                        <label for="email_opt_in" class="font-medium text-gray-900">Subscribe</label>
                                        <p id="email_opt_in-description" class="text-gray-500">Yes, I would like to receive Kuranda's Humane newsletter! </p>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <div class="mb-6">
                            <label for="order_message" class="block text-sm font-medium leading-6 text-gray-900">
                                Message to Shelter
                                <span class="text-sm font-medium text-gray-500">(Optional)</span>
                            </label>
                            <div class="mt-2">
                                <textarea
                                    rows="4"
                                    name="order_message"
                                    id="order_message"
                                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    v-model="localOrderMessage"
                                    :maxlength="orderTableNoteMaxLength"
                                    :aria-invalid="hasSessionErrorsForField('order_message') ? true : null"
                                    :aria-describedby="hasSessionErrorsForField('order_message') ? 'order_message_error' : null"
                                    @input="sanitizeOrderMessage"
                                />
                            </div>
                            <p class="mt-2 text-sm text-red-600" v-if="hasSessionErrorsForField('order_message')" id="order_message_error" v-html="getSessionErrorsForField('order_message').join('<br>')" />
                        </div>

                        <p v-if="braintreeErrorDetails !== null" v-html="braintreeErrorDetails" />
                        <div id="dropin-container"></div>
                        <input type="hidden" name="braintree_nonce" id="braintree_nonce">

                        <div class="mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between">
                            <button type="submit" class="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:w-auto disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" :disabled="submitButtonIsDisabled">
                                Submit Order
                            </button>
                            <template v-if="formIsSubmitting">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 inline-block text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <p class="inline-block ml-4">Submitting Order...</p>
                                </div>
                            </template>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
</template>

<script setup>
import { Popover, PopoverButton, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ChevronUpIcon, ChevronRightIcon, ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
import FormField from "./FormField.vue";
import {onMounted, ref} from "vue";
import dropIn from "braintree-web-drop-in";
import client from "braintree-web/client";
import dataCollector from "braintree-web/data-collector";
import * as _ from "lodash-es";

const props = defineProps({
    originalCartData: {
        type: Object,
        default: {},
    },
    braintreeToken: {
        type: String,
        required: true,
    },
    wishListLink: {
        type: String,
        required: true,
    },
    shelterName: {
        type: String,
        required: true,
    },
    shelterCity: {
        type: String,
        required: true,
    },
    shelterState: {
        type: String,
        required: true,
    },
    csrfToken: {
        type: String,
        required: true,
    },
    grandTotal: {
        type: Number,
        required: true,
    },
    fees: {
        type: Array,
        default: [],
    },
    shippingQuote: {
        type: Object,
        required: true,
    },
    taxTotal: {
        type: Number,
        required: true,
    },
    productTotal: {
        type: Number,
        required: true,
    },
    formDetails: {
        type: Object,
        required: true,
    },
    formValidationErrors: {
        type: Object,
        default: {},
    },
    countriesList: {
        type: Object,
        required: true,
    },
    zonesList: {
        type: Object,
        required: true,
    },
    orderTableVarcharMaxLength: {
        type: Number,
        required: true,
    },
    orderTableNoteMaxLength: {
        type: Number,
        required: true,
    },
});
const products = ref({});
const braintreeDeviceData = ref(null);
const braintreeErrorDetails = ref(null);
const localEmail = ref('');
const localFirstName = ref('');
const localLastName = ref('');
const localCompany = ref('');
const localAddress1 = ref('');
const localAddress2 = ref('');
const localCity = ref('');
const localZoneId = ref('0');
const localPostalCode = ref('');
const localCountryId = ref('2');
const localPhone = ref('');
const localOrderMessage = ref('');
const localAnonymous = ref(false);
const localSubscribe = ref(true);
const submitButtonIsDisabled = ref(true);
const formIsSubmitting = ref(false);
const localFormDetails = ref({});
let dropInInstance;
let checkoutButtonDisabledForSubmit;
onMounted(() => {
    products.value = props.originalCartData.products;
    localFormDetails.value = props.formDetails;
    localEmail.value = _.get(props.formDetails, 'customer_email', '');
    localFirstName.value = _.get(props.formDetails, 'customer_first_name', '');
    localLastName.value = _.get(props.formDetails, 'customer_last_name', '');
    localCompany.value = _.get(props.formDetails, 'customer_company', '');
    localAddress1.value = _.get(props.formDetails, 'customer_address_1', '');
    localAddress2.value = _.get(props.formDetails, 'customer_address_2', '');
    localCity.value = _.get(props.formDetails, 'customer_city', '');
    localZoneId.value = _.get(props.formDetails, 'customer_state', '0').toString();
    localPostalCode.value = _.get(props.formDetails, 'customer_postal_code', '');
    localCountryId.value = _.get(props.formDetails, 'customer_country', '2').toString();
    localPhone.value = _.get(props.formDetails, 'customer_phone', '');
    localAnonymous.value = _.get(props.formDetails, 'anonymous',  false);
    localSubscribe.value = _.get(props.formDetails, 'email_opt_in',  true);
    localOrderMessage.value = _.get(props.formDetails, 'order_message',  '');
    loadBraintree();
})

function loadBraintree()
{
    const createObjectConfig = {
        authorization: props.braintreeToken,
        container: '#dropin-container',
        paypal: {
            flow: 'checkout',
            amount: props.grandTotal,
            currency: 'USD',
        },
        paypalCredit: {
            flow: 'checkout',
            amount: props.grandTotal,
            currency: 'USD',
        },
        vaultManager: false
    };
    const ready = document.createElement("div");
    checkoutButtonDisabledForSubmit = false;

    ready.id = "ready";
    const createDataCollector = () => {
        client.create({
            authorization: props.braintreeToken
        }, function (err, clientInstance) {
            // Creation of any other components...

            dataCollector.create({
                client: clientInstance
            }, function (err, dataCollectorInstance) {
                if (err) {
                    // Handle error in creation of data collector
                    return;
                }
                const deviceDataInput = document.createElement('input');
                deviceDataInput.name = 'braintree_device_data';
                deviceDataInput.type = 'hidden';
                deviceDataInput.value = dataCollectorInstance.deviceData;
                document.getElementById('checkout-form').appendChild(deviceDataInput);
            });
        });
    };
    const createDropin = () => {
        if (createObjectConfig.showUpdatePayPalMenu) {
            document.querySelector('#update-paypal-configuration').className = '';
        }

        braintreeErrorDetails.value = null;

        dropIn.create(createObjectConfig, function (err, instance) {
            document.body.appendChild(ready);

            if (err) {
                // TODO this occurs when braintree fails to render the payment form.
                // we need to give some feedback to the user to tell them to reload the page
                // or something, the error below will likely be something technical and not useful
                // to the customer, so we may also want to hide this message.
                // essential to keep the throw err or a return line so the code bails out if this happens.
                ready.textContent = 'error';
                braintreeErrorDetails.value = err.message;
                throw err;
            }

            if (!createObjectConfig.disablePaymentMethodRequestableListeners) {
                if (instance.isPaymentMethodRequestable()) {
                    //payButton.removeAttribute('disabled');
                    submitButtonIsDisabled.value = false;
                }

                instance.on('changeActiveView', function (event) {
                    //console.log('change active view', event);
                });
                instance.on('paymentMethodRequestable', function (event) {
                    if (checkoutButtonDisabledForSubmit === false) {
                        //payButton.removeAttribute('disabled');
                        submitButtonIsDisabled.value = false;
                    }
                    //console.log('paymentMethodRequestable', event);
                });

                instance.on('noPaymentMethodRequestable', function (event) {
                    //payButton.setAttribute('disabled', true);
                    submitButtonIsDisabled.value = true;
                    //console.log('noPaymentMethodRequestable');
                });
            } else {
                //payButton.removeAttribute('disabled');
                submitButtonIsDisabled.value = false;
            }

            if (!createObjectConfig.disablePaymentOptionSelectedListener) {
                instance.on('paymentOptionSelected', function (event) {
                    //console.log('paymentOptionSelected', event.paymentOption);
                });
            }

            //createButton.setAttribute('disabled', true);

            dropInInstance = instance;

            ready.textContent = 'ready';
        });
    };
    //createButton.addEventListener('click', createDropin);
    createDropin();
    createDataCollector();
}
function submitForm(event)
{
    event.preventDefault();
    // stop multiple submissions
    if (submitButtonIsDisabled.value === true) {
        return;
    }
    const requestPaymentMethodOptions = {};
    // TODO
    // if we want to add our own client side form validation that checks before
    // sending to Braintree, add it here and early return if it fails.
    dropInInstance.requestPaymentMethod(requestPaymentMethodOptions, function (err, payload) {
        if (err) {
            // TODO
            // docs say
            // If a payment method is not available, an error will appear in the UI.
            // https://braintree.github.io/braintree-web-drop-in/docs/current/Dropin.html#requestPaymentMethod
            // I haven't found how we can test this response (entering a credit card type we dont accept,
            // but my sandbox braintree account takes all types and I cant change it, maybe you can find a way)
            // we will want to see if this message is on that is useful to the customer or we need to write
            // our own and ensure it's displayed.
            braintreeErrorDetails.value = err.message;
        } else {
            // no error was found, so we can submit to the backend once we inject the nonce into
            // our own form.
            checkoutButtonDisabledForSubmit = true;
            //payButton.setAttribute('disabled', true);
            submitButtonIsDisabled.value = true;
            document.getElementById('braintree_nonce').value = payload.nonce;
            document.querySelector('#checkout-form').submit();
            formIsSubmitting.value = true;
        }
    });
}
function getSessionErrorsForField(fieldName)
{
    return _.get(props.formValidationErrors, fieldName, []);
}
function hasSessionErrorsForField(fieldName)
{
    return getSessionErrorsForField(fieldName).length;
}
function getSelectInputClasses(fieldName)
{
    const commonClasses = 'mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset focus:ring-2 sm:text-sm sm:leading-6';
    if (hasSessionErrorsForField(fieldName)) {
        return `${commonClasses} text-red-900 ring-red-300 focus:ring-red-500`;
    }
    return `${commonClasses} text-gray-900 ring-gray-300 focus:ring-indigo-500`;
}
function sanitizeOrderMessage()
{
  localOrderMessage.value = removeEmojiFromString(localOrderMessage.value);
}
function removeEmojiFromString(stringToSanitize) {
    const ranges = [
        '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
        '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
        '\ud83d[\ude80-\udeff]'  // U+1F680 to U+1F6FF
    ];
    return stringToSanitize.replace(window.emojiRegex(), '')
}

</script>
