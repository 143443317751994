<script setup>
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'
const model = defineModel()
const props = defineProps({
    labelText: {
        type: String,
        required: true,
    },
    fieldName: {
        type: String,
        required: true,
    },
    fieldType: {
        type: String,
        default: 'text',
    },
    autoCompleteType: {
        type: String,
        default: null,
    },
    optional: {
        type: Boolean,
        default: false,
    },
    maxLength: {
        type: Number,
        default: null,
    },
    errorMessages: {
        type: Array,
        default: [],
    },
});
function hasErrorMessage() {
    return props.errorMessages.length > 0;
}
function getInputClasses()
{
    const commonClasses = 'block w-full rounded-md shadow-sm sm:text-sm';
    if (hasErrorMessage()) {
        return `${commonClasses} text-red-900 border-red-300 focus:border-red-500 focus:ring-red-500`;
    }
    return `${commonClasses} text-gray-700 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500`;
}
</script>

<template>
    <label :for="fieldName" class="block text-sm font-medium">
        {{ labelText }}
        <span class="text-sm font-medium text-gray-500" v-if="optional === true">(Optional)</span>
    </label>
    <div class="mt-1 relative">
        <input
            :type="fieldType"
            :id="fieldName"
            :name="fieldName"
            :class="getInputClasses()"
            v-model="model"
            :maxlength="maxLength"
            :autocomplete="autoCompleteType"
            :required="optional === false"
            :aria-invalid="hasErrorMessage() ? true : null"
            :aria-describedby="hasErrorMessage() ? `${fieldName}_error` : null"
        />
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" v-if="hasErrorMessage()" />
        </div>
    </div>
    <p class="mt-2 text-sm text-red-600" v-if="hasErrorMessage()" :id="`${fieldName}_error`" v-html="props.errorMessages.join('<br>')" />

</template>

<style scoped>

</style>
