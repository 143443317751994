<script>
export default {
    mounted() {
        let cognitoScript = document.createElement('script')
        cognitoScript.setAttribute('src', 'https://www.cognitoforms.com/f/seamless.js')
        cognitoScript.setAttribute('data-key', this.data_key)
        cognitoScript.setAttribute('data-form', this.data_form)
        document.getElementById(this.destination_id).appendChild(cognitoScript)
        if (this.prefill !== null) {
            const localPrefill = this.prefill;
            setTimeout(function(){
                Cognito.prefill(localPrefill)
            }, 250);
        }
    },
    props: {
        data_key: {
            type: String,
        },
        data_form: {
            type: String,
        },
        destination_id: {
            type: String,
        },
        prefill: {
            type: Object,
            default: null,
        },
    }
}
</script>

<template>
    <div></div>
</template>

<style scoped>

</style>
