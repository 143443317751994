<script setup>

</script>

<template>
    <div class="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-6 sm:py-8 lg:px-8 lg:py-12">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900 font-alternate">Still have questions? We’ve got answers:</h2>
        <dl class="mt-10 space-y-8 divide-y divide-gray-900/10">
            <div class="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                <dt class="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Do I get a discount?</dt>
                <dd class="mt-4 lg:col-span-7 lg:mt-0">
                    <p class="text-base leading-7 text-gray-600">Yes! All items that are donated to a Shelter or Rescue get discounted prices.</p>
                </dd>
            </div>
            <div class="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                <dt class="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Do I get free shipping?</dt>
                <dd class="mt-4 lg:col-span-7 lg:mt-0">
                    <p class="text-base leading-7 text-gray-600">All donations shipping to the lower 48 U.S. states receive free shipping.</p>
                    <p class="text-base leading-7 text-gray-600">Shipping charges are added to donations going to Alaska, Hawaii and Puerto Rico.</p>
                    <p class="text-base leading-7 text-gray-600">Shipping and brokerage fees are added to donations going to Canada. All prices are in U.S. Dollars.</p>
                </dd>
            </div>
            <div class="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                <dt class="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Where does my donation go?</dt>
                <dd class="mt-4 lg:col-span-7 lg:mt-0">
                    <p class="text-base leading-7 text-gray-600">Each shelter has already filled out their shipping address for your donation order. All orders ship directly to the shelter from Kuranda. You and the organization will receive tracking information, so you both will know when your donated items are delivered!</p>
                </dd>
            </div>
        </dl>
    </div>
</template>

<style scoped>

</style>
