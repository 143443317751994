<template>
    <div class="mt-4">
        <label for="shelter-search-query" class="block text-left font-medium leading-6 text-white md:text-gray-900">Search for a Shelter</label>
        <div class="relative mt-2">
            <form @submit.prevent="search()">
                <div class="grid grid-cols-6">
                    <input type="text" name="shelter-search-query" id="shelter-search-query" class="mb-4 lg:mb-0 col-span-6 lg:col-span-4 block rounded-md lg:rounded-r-none border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 lg:text-sm lg:leading-6" v-model="search_query" />
                    <label for="search_type" class="sr-only">Choose how you'd like to search</label>
                    <select id="search_type" name="search_type" v-model="search_type" class="col-span-6 lg:col-span-2 h-full w-full border-0 lg:py-0 pl-7 lg:pl-4 pr-7 text-gray-500 rounded-md lg:rounded-l-none ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:mx-auto">
                        <option value="location">Search by Location</option>
                        <option value="name">Search by Organization Name</option>
                    </select>
                    <button type="submit" class="col-span-6 lg:col-span-2 lg:col-start-3 mt-4 w-full rounded bg-orange-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-orange-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Search</button>
                </div>
            </form>
        </div>
        <template v-if="loading === true">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </template>
        <template v-if="rate_limit_reached === true && showing_search_results && !loading">
            <h2 class="!text-lg">Too Many Search Attempts. Please Try Again In A Moment.</h2>
        </template>
        <template v-if="search_error_message !== null && showing_search_results && !loading">
            <h2 class="!text-lg" v-html="search_error_message"/>
        </template>
        <template v-if="organizations.length && showing_search_results && !loading">
            <div class="my-4" v-for="organization in organizations" :key="organization.id">
                <div class="border-b-2 mb-4 pb-4">
                    <h2 class="!text-lg">{{ organization.name }}
                        - {{ organization.city }}, {{ organization.state }}
                    </h2>
                    <a :href="dashboardRoute(organization.id)">Dashboard for {{ organization.name }}</a>
                </div>
            </div>
        </template>
        <template v-if="!loading && (!organizations.length) && !search_error_message && searched_once">
            <p>
                No organizations were found matching that search.
            </p>
            <p>
                If you know your unique wish list link, you can get to your dashboard by taking the number from it:
                <br>Wish list link: https://shelterbeds.org/donate/your_number_is_here
                <br>
                And adding it to the following:<br>
                <br>Dashboard Link: https://shelterbeds.org/dashboard/your_number_goes_here
            </p>
        </template>
    </div>
</template>

<script>
export default {

    data() {
        return {
            search_query: '',
            organizations: [],
            search_error_message: null,
            rate_limit_reached: false,
            loading: false,
            searched_once: false,
            showing_search_results: false,
            search_type: 'location',
        };
    },
    methods: {
        dashboardRoute: function(shelterId) {
            return route('dashboard.index', {shelter: shelterId});
        },
        isNameSearch: function() {
            return this.search_type === 'name';
        },
        search: function(options = {}) {
            this.loading = true;
            this.search_error_message = null;
            this.rate_limit_reached = false;
            this.organizations = [];
            this.showing_search_results = true;
            let searchRoute = '';
            let postBody = {};
            if (this.isNameSearch()) {
                searchRoute = route('getSheltersByName');
                postBody = {
                    name: this.search_query,
                };
            } else {
                searchRoute = route('getSheltersForLocation');
                postBody = {
                    address: this.search_query,
                };
            }
            axios.post(searchRoute, postBody)
                .then(function (response) {
                    this.organizations = response.data.organizations;
                    this.search_error_message = response.data.error_message ? response.data.error_message : null;
                }.bind(this))
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status && error.response.status === 429 ) {
                            this.rate_limit_reached = true;
                            return;
                        }
                        if (error.response.status && error.response.status === 422 ) {
                            this.search_error_message = 'Please enter a search query';
                            return;
                        }
                    } else if (error.request) {
                        //
                    } else {
                        //
                    }
                }.bind(this))
                .finally(function () {
                    this.loading = false;
                    this.searched_once = true;
                }.bind(this));
        },
    }
};
</script>
<style>

</style>
