<script setup>
import { ref, onMounted } from 'vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import * as _ from "lodash-es";

const open = ref(false)

function getCartQuantitiesForItem(minQuantity, maxQuantity)
{
    const quantities = [];
    let currentQuantity = minQuantity;
    while (currentQuantity <= maxQuantity) {
        quantities.push(
           currentQuantity
        );
        currentQuantity++;
    }
    return quantities;
}
function getSessionErrorsForField(fieldName)
{
    return _.get(props.formValidationErrors, fieldName, []);
}
function hasSessionErrorsForField(fieldName)
{
    return getSessionErrorsForField(fieldName).length;
}
function getInputClasses(fieldName)
{
    const commonClasses = 'block w-full rounded lg:rounded-r-none lg:rounded-l-md border-0 py-1.5 pl-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 sm:w-full';
    if (hasSessionErrorsForField(fieldName)) {
        return `${commonClasses} text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500`;
    }
    return `${commonClasses} text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600`;
}
function sendGaEvent()
{
    window.gtag('event', 'view_cart', {
        value: _.get(props.cartProductsJsForGoogle, 'total', 0),
        currency: _.get(props.cartProductsJsForGoogle, 'currency', ''),
        items: _.get(props.cartProductsJsForGoogle, 'products', []),
    })
}
const props = defineProps({
    formValidationErrors: {
        type: Object,
        default: [],
    },
    originalCartData: {
        type: Object,
        default: [],
    },
    continueShoppingLink: {
        type: String,
        default: '',
    },
    csrfToken: {
        type: String,
        default: '',
    },
    cartProductsJsForGoogle: {
        type: Object,
        default: [],
    }
});
const products = ref([]);
onMounted(() => {
    products.value = props.originalCartData.products;
    if (Object.entries(props.cartProductsJsForGoogle).length) {
        sendGaEvent();
    }
})
</script>

<template>
    <div class="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
        <section aria-labelledby="cart-heading" class="lg:col-span-7">
            <h2 id="cart-heading" class="sr-only">Items in your shopping cart</h2>

            <ul role="list" class="divide-y divide-gray-200 border-b border-t border-gray-200">
                <li v-for="product in products" :key="product.cart_product_id" class="flex py-6 sm:py-10">
                    <div class="flex-shrink-0">
                        <img :src="product.image" :alt="product.image_alt_text" class="h-24 w-24 rounded-md object-contain object-center sm:h-48 sm:w-48" />
                    </div>

                    <div class="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                        <div class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                            <div>
                                <div class="flex justify-between">
                                    <span class="text-sm">
                                        {{ product.name }}
                                    </span>
                                </div>
                                <div class="mt-1 flex text-sm">
                                    <p v-if="product.description" class="text-gray-500">{{ product.description }}</p>
                                </div>
                                <p v-if="product.original_price && product.original_price > product.unit_price" class="mt-1 text-sm font-medium text-gray-600 line-through">${{ product.original_price.toFixed(2) }}</p>
                                <p class="mt-1 text-sm font-medium text-gray-900">${{ product.unit_price.toFixed(2) }}</p>
                            </div>

                            <div class="mt-4 sm:mt-0 sm:pr-9">
                                <form method="post" class="mx-auto" :action="route('cart.editCartProduct', product.cart_product_id)">
                                    <input type="hidden" :value="props.csrfToken" name="_token" autocomplete="off">
                                    <div class="grid grid-cols-1 lg:grid-cols-2">
                                        <label :for="product.quantity_field_key" class="sr-only">Quantity, {{ product.name }}</label>
                                        <select :id="product.quantity_field_key" :name="product.quantity_field_key" :class="getInputClasses(product.quantity_field_key)" v-model="product.quantity" :aria-invalid="hasSessionErrorsForField(product.quantity_field_key) ? true : null" :aria-describedby="hasSessionErrorsForField(product.quantity_field_key) ? `${product.quantity_field_key}_error` : null">
                                            <option v-for="quantity in getCartQuantitiesForItem(1, product.max_quantity_can_purchase)" :key="`${quantity}_${product.cart_product_id}`" >{{ quantity }}</option>
                                        </select>
                                        <button type="submit" class="relative w-full text-center rounded mt-4 lg:mt-0 lg:rounded-l-none lg:rounded-r-md gap-x-2 bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                            Update
                                        </button>
                                    </div>
                                    <p class="mt-2 text-sm text-red-600" :id="`${product.quantity_field_key}_error`" v-if="hasSessionErrorsForField(product.quantity_field_key)" v-html="getSessionErrorsForField(product.quantity_field_key).join('<br>')"></p>
                                </form>
                                <div class="absolute right-0 top-0">
                                    <form method="post" class="mx-auto" :action="route('cart.editCartProduct', product.cart_product_id)">
                                        <input type="hidden" :value="props.csrfToken" name="_token" autocomplete="off">
                                        <input type="hidden" :name="product.quantity_field_key" value="0">
                                        <button type="submit" class="-m-2 inline-flex p-2 text-gray-600 hover:text-gray-600">
                                            <span class="sr-only">Remove, {{ product.name }}</span>
                                            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </section>

        <!-- Order summary -->
        <section aria-labelledby="summary-heading" class="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8">
            <h2 id="summary-heading" class="text-lg font-medium text-gray-900">Order summary</h2>

            <dl class="mt-6 space-y-4">
                <div class="flex items-center justify-between">
                    <dt class="text-sm text-gray-600">Subtotal</dt>
                    <dd class="text-sm font-medium text-gray-900">${{ props.originalCartData.product_total_price.toFixed(2) }}</dd>
                </div>
            </dl>

            <div class="mt-6">
                <a :href="continueShoppingLink" class="w-full block text-center rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Continue Shopping</a>
                <a :href="route('checkout.create')" class="w-full block text-center mt-4 rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Checkout</a>
            </div>
        </section>
    </div>
</template>

<style scoped>

</style>
